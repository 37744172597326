.logo {
    display: block;
    width: 191px;
    @media (max-width: 767px) {
        width: 151px; } }

.hamburger-icon {
    width: 31px;
    height: 24px;
    position: relative;
    cursor: pointer;
    display: block;
    &__line {
        display: block;
        height: 4px;
        background: $red;
        transition: .3s ease;
        &:nth-child(2) {
            margin: 6px 0;
            opacity: 1; } }
    &.active {
        .hamburger-icon__line {
            &:first-child {
                transform: rotate(45deg) translateX(8px) translateY(8px);
                transform-origin: center center; }
            &:nth-child(2) {
                transform: translateX(100px);
                opacity: 0; }
            &:last-child {
                transform: rotate(-45deg) translateX(6px) translateY(-6px);
                transform-origin: center center; } } } }

.block-title {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    margin: 0; }

.hint-link {
    &__text {
        font-size: 14px;
        color: #9ca5ac;
        border-bottom: 1px dashed rgba(#adadad, .5);
        transition: .15s ease;
        margin-right: 2px; }
    &__icon {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-bottom: 1px;
        path {
            fill: #d1d5d8;
            transition: .15s ease; } }
    &:hover {
        path {
            fill: $red; }
        .hint-link__text {
            color: $red;
            border-bottom: 1px dashed rgba($red, .5); } } }

.slider-wrapper {
    position: relative;
    margin: 0 0 20px;
    .lSPager {
        display: none; }
    @media (max-width: 767px) {
        margin: 0 0 25px; }
    @media (max-width: 767px) {
        background: #f3f3f3;
        padding-bottom: 1px;
        .lSPager {
            display: block; } } }
.slider {
    overflow: hidden; }
.slide {
    padding: 24px 0;
    outline: none;
    float: left;
    @media (max-width: 767px) {
        padding: 40px 0 20px; }
    .container {
        @extend %flex-center;
        min-height: 407px;
        @media (max-width: 1023px) {
            padding: 0 60px; }
        @media (max-width: 767px) {
            padding: 0 20px; }
        @media (max-width: 767px) {
            min-height: inherit;
            flex-direction: column-reverse; } }
    &__ {
        &title {
            font-size: 60px;
            font-weight: 300;
            line-height: 1;
            margin: 15px 0 20px;
            @media (max-width: 1023px) {
                font-size: 45px; }
            @media (max-width: 767px) {
                font-weight: 400;
                font-size: 40px; } }
        &text, &image {
            width: 50%;
            @media (max-width: 1023px) {
                width: auto; } }
        &text {
            max-width: 470px;
            padding-right: 60px;
            @media (max-width: 1023px) {
                padding-right: 25px; }
            @media (max-width: 767px) {
                max-width: inherit;
                padding: 0; } }
        &image {
            padding-left: 12px;
            padding-right: 40px;
            margin-left: auto;
            @media (max-width: 1023px) {
                padding: 0 10px;
                margin-left: auto;
                max-width: 360px; }
            @media (max-width: 767px) {
                padding: 0 40px;
                margin: 0 auto; } } }
    .btn-rect {
        outline: none;
        margin-top: 16px;
        @media (max-width: 767px) {
            margin-top: 8px; } } }

.slider {
    &- {
        &controls {
            max-width: 1200px;
            margin: -19px auto 0;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            @media (max-width: 767px) {
                top: 0;
                margin-top: 130px; } }
        &prev-btn, &next-btn {
            position: absolute;
            top: 0;
            cursor: pointer;
            path {
                fill: #d2d6d9;
                transition: .2s ease; }
            &:hover {
                path {
                    fill: $red; } } }
        &prev-btn {
            left: 20px; }
        &next-btn {
            right: 20px; } } }

.features {
    padding: 27px 0 70px;
    border-bottom: 1px solid #d1d5d8;
    margin-bottom: 47px;
    @media (max-width: 767px) {
        display: none; }
    .container {
        @extend %flex-start;
        max-width: 950px; } }
.feature {
    width: 33.33%;
    padding: 0 3%;
    text-align: center;
    &__ {
        &icon {
            height: 27px;
            margin-bottom: 41px; } } }

.elastic-categories {
    text-align: center;
    padding: 0;
    margin-bottom: 14px;
    @media (max-width: 1023px) {
        margin-bottom: 62px; }
    @media (max-width: 767px) {
        margin-bottom: -7px; }
    &__ {
        &list {
            @extend %flex-stretch;
            @media (max-width: 1023px) {
                padding-top: 20px; }
            @media (max-width: 767px) {
                display: block; } }
        &item {
            width: 25%;
            @media (max-width: 767px) {
                width: 100%; } } } }
.elastic-category {
    padding: 56px 0 74px;
    height: 100%;
    @media (max-width: 1023px) {
        padding: 37px 0 18px; }
    @media (max-width: 767px) {
        padding: 7px 20px 52px; }
    &:hover {
        margin: 0 -10px;
        transition: .3s ease;
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.22);
        background: #fff;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: 767px) {
            padding-left: 30px;
            padding-right: 30px;
            box-shadow: none;
            position: static; } }
    &__ {
        &image {
            &-container {}
            & {
                padding-bottom: 60%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                @media (max-width: 767px) {
                    padding-bottom: 54%; } } }
        &title {
            font-size: 26px;
            font-weight: 400;
            margin: 27px 0 23px;
            @media (max-width: 1023px) {
                font-size: 18px;
                margin: 15px 0 18px; }
            @media (max-width: 767px) {
                font-size: 26px;
                margin: 21px  0 13px; } } } }

.elastic-subcategories {
    padding: 0;
    max-width: 215px;
    margin: 0 auto;
    list-style: none;
    @media (max-width: 1023px) {
        max-width: 175px; }
    @media (max-width: 767px) {
        max-width: 215px; } }
.elastic-subcategory {
    display: inline-block;
    &__ {
        &title {
            margin: 0;
            font-weight: normal;
            text-decoration: underline; } }
    &:not(:last-child) {
        .elastic-subcategory__title:after {
            content: ','; } } }

.brands-by-alphabet {
    padding-bottom: 22px;
    border-bottom: 1px solid #d1d5d8;
    @media (max-width: 767px) {
        margin-bottom: 20px; }
    &__ {
        &list {
            column-count: 6;
            column-gap: 80px;
            margin-top: 65px;
            @media (max-width: 1023px) {
                margin-top: 35px;
                column-count: 4;
                column-gap: 0; }
            @media (max-width: 767px) {
                columns: 1; } } } }

.brands-btn {
    margin-bottom: 50px; }

.seo-text {
    padding: 38px 0 72px;
    @media (max-width: 1023px) {
        padding: 38px 0 155px; }
    @media (max-width: 767px) {
        display: none; }
    &__ {
        &title {
            margin: 63px 0;
            font-size: 40px;
            line-height: 50px;
            font-weight: 400;
            @media (max-width: 1023px) {
                margin: 16px 0 37px; } }
        &content {
            column-count: 2;
            column-gap: 120px;
            font-size: 20px;
            @media (max-width: 1023px) {
                column-gap: 50px; }
            p {
                margin: 13px 0 16px;
                break-inside: avoid-column;
                page-break-inside: avoid;
                break-inside: avoid-column;
                display: inline-block;
                width: 100%; } } } }

.socials {
    @extend %flex-center;
    max-width: 149px;
    width: 100%;
    justify-content: space-between;
    margin-right: 49px;
    @media (max-width: 767px) {
        margin-right: 40px; }
    @media (max-width: 374px) {
        margin-right: 30px;
        max-width: 110px; }
    &__ {
        &icon {
            display: block; } } }

.copyright {
    font-size: 14px;
    &__ {
        &sitename {
            color: #fff; } } }

.product-card {
    position: relative;
    padding-bottom: 13px;
    @media (max-width: 1023px) {
        padding-bottom: 20px; }
    &__ {
        &title {
            font-size: 40px;
            font-weight: 500;
            margin: 15px 0 -55px;
            padding-right: 240px;
            line-height: 1;
            @media (max-width: 1023px) {
                margin-top: 20px; }
            @media (max-width: 767px) {
                margin: 0;
                padding-right: 0;
                font-size: 30px;
                line-height: 40px; } }
        &columns {
            display: flex;
            @media (max-width: 767px) {
                display: block; } }
        &slider {
            width: 400px;
            padding-top: 100px;
            @media (max-width: 1023px) {
                width: 304px;
                padding-top: 165px; }
            @media (max-width: 767px) {
                width: 100%;
                padding: 27px 15px 0;
                .lSPager {
                    display: none; } }
            .lSGallery {
                li.active {
                    opacity: .5;
                    transition: .15s; }
                a {
                    @extend %flex-center; }
                img {
                    max-height: 60px;
                    margin: auto; } } }
        &content {
            width: 100%;
            padding-left: 20px;
            padding-top: 22px;
            @media (max-width: 767px) {
                padding-top: 27px;
                padding-left: 0; } }
        &properties_rating_reviews_price_hint-link {
            display: flex;
            flex-direction: column-reverse;
            @media (max-width: 767px) {
                flex-direction: column; } }
        &price_hint-link {
            margin-left: auto;
            text-align: right;
            margin-bottom: 15px;
            .product-price {
                color: $red; }
            @media (max-width: 767px) {
                display: flex;
                flex-direction: row-reverse;
                margin: 0;
                padding: 24px 0;
                .hint-link {
                    max-width: 120px;
                    text-align: left;
                    margin-right: auto;
                    line-height: 18px; }
                .product-price {
                    padding: 8px 0 4px; } } }
        &properties_rating_reviews {
            @extend %flex-start;
            @media (max-width: 767px) {
                padding-bottom: 18px;
                border-bottom: 1px solid #e8eaeb; } }
        &rating_reviews {
            margin-left: auto;
            text-align: right;
            @media (max-width: 767px) {
                .product-rating {
                    margin: 0 0 8px auto;
                    float: none; }
                .product-reviews-link {
                    margin: 0; } } }
        &buy-btn {
            text-align: right;
            margin-bottom: 22px;
            @media (max-width: 767px) {
                margin-bottom: 37px; } }
        &properties {
            margin-top: 6px;
            @media (max-width: 1023px) {
                font-size: 15px;
                position: relative;
                top: 47px; }
            @media (max-width: 767px) {
                position: static;
                margin-top: 11px;
                .product-property {
                    margin-bottom: 4px; } } } }
    .btn-gray {
        margin-top: 22px;
        @media (max-width: 767px) {
            margin-top: 31px; } } }

.product-price {
    line-height: 24px;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 8px;
    span {
        font-size: 24px;
        font-weight: 500; } }

.product-rating {
    border-radius: 2px;
    background-color: #41c200;
    width: 44px;
    text-align: center;
    height: 26px;
    line-height: 26px;
    font-size: 17px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700;
    color: #fff;
    float: right; }

.product-reviews-link {
    color: #169bd5;
    font-size: 14px;
    font-weight: 500;
    margin-right: 21px;
    border-bottom: 1px dashed;
    line-height: 1.2; }

.product-properties {
    .product-property {
        display: flex;
        max-width: 240px;
        &__ {
            &name {
                color: #9ca5ac;
                margin-right: 37px;
                @media (max-width: 1023px) {
                    margin-right: 20px; } }
            &name, &value {
                font-weight: 500; } } } }


.product-card-slider {
    @extend %flex-center;
    &__image {
        cursor: zoom-in;
        margin: 0 auto;
        display: block; } }

.product-card-tabs {
    border-top: 1px solid #d1d5d8;
    border-bottom: 1px solid #d1d5d8;
    padding: 38px 0 90px;
    @media (max-width: 767px) {
        padding: 12px 0 20px; }
    .select {
        margin: 30px 0 50px;
        font-size: 20px;
        align-items: center;
        @media (max-width: 767px) {
            margin: 10px 0 50px; }
        &__title {
            font-size: 20px;
            margin-right: 30px; }
        .selectric-wrapper {
            max-width: 250px;
            background: #f8f8f8; } } }

.shops-list {
    @extend %flex-justify;
    flex-wrap: wrap;
    margin-bottom: 20px;
    &__item {
        margin-bottom: 22px;
        height: 70px;
        width: 440px;
        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 27px;
            height: auto;
            min-height: 70px;
            &:nth-child(2) {
                margin-bottom: 0;
                &~div {
                    display: none; } } } }
    &__btn {
        text-align: center; } }
.shop {
    transition: box-shadow .3s;
    margin: -40px -40px -20px;
    padding: 40px 40px 20px;
    @media (max-width: 767px) {
        margin-bottom: 0; }
    .shop__contacts-link {
        transition: border .15s ease, color .15s ease; }
    &.is-active {
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.13);
        position: relative;
        background: #fff;
        .shop__contacts {
            display: block; }
        .shop__contacts-link {
            font-size: 0;
            border: none;
            &:before {
                content: 'Скрыть адреса магазинов';
                font-size: 14px;
                border-bottom: 1px dashed;
                color: $red; } } }
    &__ {
        &short {
            @extend %flex-start;
            @media (max-width: 767px) {
                display: block; } }
        &logo {
            width: 173px;
            height: 70px;
            flex-shrink: 0;
            flex-grow: 0;
            @media (max-width: 767px) {
                height: auto; } }
        &links {
            padding-left: 27px;
            font-size: 14px;
            font-weight: 400;
            @media (max-width: 767px) {
                padding: 0; } }
        &buy-link {
            color: #169bd5;
            text-decoration: underline;
            display: table;
            margin: 2px 0; }
        &contacts {
            display: none;
            max-width: 425px;
            line-height: 20px;
            padding-top: 20px; }
        &contacts-link {
            color: #9ca5ac;
            border-bottom: 1px dashed; } } }

.shop-contact {
    @extend %flex-start;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 20px;
    &__ {
        &index {
            width: 50px;
            color: #9ca5ac;
            @media (max-width: 767px) {
                width: 30px; } }
        &phone {
            margin-left: auto;
            padding-left: 30px;
            white-space: nowrap;
            @media (max-width: 767px) {
                padding-left: 20px; } } } }

.row-products {
    margin: 50px 0 50px;
    @media (max-width: 767px) {
        margin: 40px 0 40px; }
    &__ {
        &list {
            @extend %flex-justify;
            flex-wrap: wrap;
            margin: 0 -14px;
            @media (max-width: 767px) {
                margin: 0; } }
        &title {
            margin: 0;
            font-size: 30px;
            font-weight: 500;
            line-height: 1;
            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 30px;
                margin: 0 0 15px; } } } }

.row-product {
    width: 25%;
    padding: 0 14px;
    position: relative;
    margin: 50px 0 50px;
    @media (max-width: 1023px) {
        width: 33.33%;
        margin-bottom: 30px;
        &:nth-child(4) {
            display: none; } }
    @media (max-width: 767px) {
        width: 100%;
        border-bottom: 1px solid rgba(209,213,216,0.5);
        padding: 36px 0 10px;
        margin: 0;
        &:nth-child(2)+div {
            display: none; } }
    & {
        &__ {
            &inner {
                @media (max-width: 767px) {
                    display: flex; } }
            &image {
                padding-bottom: 78%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                @media (max-width: 767px) {
                    width: 110px;
                    margin-right: 20px; } }
            &link {
                color: #169bd5;
                text-decoration: underline; }
            &title {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                margin: 25px 0 12px;
                color: inherit;
                @media (max-width: 767px) {
                    margin: 0 0 23px; } } }
        .product-properties {
            font-size: 14px;
            margin: 0 0 14px;
            @media (max-width: 767px) {
                margin-bottom: 23px; } }
        .product-property {
            padding-bottom: 5px;
            &__name {
                margin-right: 20px;
                @media (max-width: 375px) {
                    margin-right: 4px; } } }
        .product-reviews-link {
            border-bottom: none;
            text-decoration: underline;
            display: inline-block;
            margin: 7px 0 0;
            &:hover {
                text-decoration: none; }
            @media (max-width: 767px) {
                margin: 4px 0 0 20px; } }
        .product-rating {
            position: absolute;
            top: 0;
            @media (max-width: 767px) {
                position: static;
                float: left; } }
        .product-price {
            float: right;
            color: #000;
            font-size: 30px; } } }

.main {
    .sort {
        .selectric-wrapper {
            display: inline-block;
            vertical-align: bottom;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            max-width: 150px;
            height: auto;
            line-height: inherit;
            box-shadow: none;
            bottom: 2px;
            background: transparent;
            .label {
                padding: 0;
                border-bottom: 1px dashed rgba(173,173,173,0.5);
                line-height: 1.3;
                margin: 0;
                display: inline-block;
                vertical-align: middle; }
            .button {
                display: none; }
            .selectric-items {
                min-width: 150px; } } } }

.sort {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    @media (max-width: 767px) {
        display: block; }
    &-by {
        display: flex;
        @media (max-width: 767px) {
            display: block; }
        &__ {
            &title {
                font-size: 16px;
                font-weight: 500;
                margin-right: 5px;
                margin-top: 1px; }
            &list {
                display: flex;
                align-items: flex-end;
                @media (max-width: 767px) {
                    display: block;
                    margin-bottom: 20px; } }
            &item {
                color: #9ca5ac;
                cursor: pointer;
                position: relative;
                padding: 0 20px;
                @media (max-width: 767px) {
                    display: table;
                    padding: 0;
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 22px;
                    .selectric-wrapper {
                        font-size: 20px!important;
                        vertical-align: middle!important;
                        margin-left: 5px;
                        .label {
                            max-width: 100px;
                            width: 100%; }
                        .selectric-items {
                            left: inherit;
                            right: 0; } } }
                @media (max-width: 374px) {
                    font-size: 16px;
                    margin-top: 5px;
                    .selectric-wrapper {
                        font-size: 16px!important;
                        .label {
                            max-width: 100px;
                            width: 100%; }
                        .selectric-items {
                            left: inherit;
                            right: 0; } } }
                &:after {
                    content: '|';
                    position: absolute;
                    right: -4px;
                    color: rgba(#c4c9cd, .5);
                    pointer-events: none;
                    @media (max-width: 767px) {
                        display: none; } }
                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none; } }
                &.active {
                    color: $red;
                    transition: .15s; } }
            &selected {
                color: $red;
                font-size: 20px;
                font-weight: 500;
                line-height: 22px; }
            &selected-dropdown-arrow {
                vertical-align: middle; }
            &city {
                max-width: 65px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                border-bottom: 1px dashed rgba(173,173,173,0.5);
                line-height: 1.3;
                margin: 0;
                display: inline-block;
                vertical-align: middle;
                color: #000;
                font-weight: 500;
                margin-bottom: 2px; } }
        .selectric-wrapper {
            .label {
                max-width: 70px;
                position: relative; } } }
    &-show {
        &__ {
            &label {
                display: flex;
                align-items: flex-end;
                cursor: pointer; }
            &title {
                margin-right: 6px;
                color: #9ca5ac; } }
        .selectric-wrapper {
            .label {
                max-width: 110px; }
            .selectric-items {
                left: inherit;
                right: 0; } } } }

.category-product {
    &:last-child {
        .category-product__inner {
            border-bottom: none; } }
    &__ {
        &inner {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid rgba(#d1d5d8, .5);
            margin-bottom: 37px; }
        &image-container {
            margin-right: 30px;
            @media (max-width: 767px) {
                margin-right: 20px; } }
        &image {
            width: 220px;
            padding-bottom: 100%;
            background-position: top center;
            background-size: contain;
            background-repeat: no-repeat;
            @media (max-width: 767px) {
                width: 110px; } }
        &content {
            width: 100%;
            position: relative; }
        &group {
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid rgba(#d1d5d8, .5);
            padding-bottom: 19px;
            margin-bottom: 22px;
            .product-properties {
                font-size: 14px;
                .product-property {
                    margin-bottom: 5px;
                    &__name {
                        margin-right: 20px; } } }
            @media (max-width: 767px) {
                display: block;
                border-bottom: none;
                margin-bottom: 0; } }
        &link {
            color: #169bd5;
            font-size: 26px;
            line-height: 30px;
            text-decoration: underline; }
        &title {
            margin: 0 0 18px;
            font-weight: 400;
            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 30px; } }
        &price_reviews_rating {
            margin-left: auto;
            margin-bottom: 2px;
            width: 160px;
            text-align: right;
            flex-grow: 0;
            flex-shrink: 0;
            @media (max-width: 767px) {
                display: block;
                margin-left: -130px;
                width: auto;
                text-align: left;
                margin-top: 29px; }
            .product-price {
                position: absolute;
                top: 4px;
                right: 0;
                font-size: 30px;
                @media (max-width: 767px) {
                    position: static;
                    float: right; } }
            .product-rating {
                @media (max-width: 767px) {
                    float: left;
                    margin-right: 20px; } } }
        &shops {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;
            @media (max-width: 767px) {
                display: none; }
            .small-shop {
                width: 33.33%;
                padding: 0 20px;
                margin-bottom: 26px; } } } }

.small-shop {
    &__image {
        height: 45px;
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat; }
    &__link {
        color: #169bd5;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        white-space: nowrap; } }

.aside-video {
    margin-top: 40px;
    margin-bottom: 44px;
    &__ {
        &embed {
            position: relative;
            padding-bottom: 55.9%;
            margin-bottom: 15px;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%; } }
        &title {
            font-size: 20px;
            font-weight: 500;
            text-decoration: underline;
            line-height: 1.2; } } }

.aside-send-request {
    background: #dff5ff;
    padding: 24px 22px 18px 18px;
    margin-bottom: 40px;
    &__ {
        &title {
            font-size: 23px;
            line-height: 20px;
            margin: 0;
            font-weight: 600; }
        &description {
            font-size: 14px;
            p {
                margin: 15px 0 18px;
                line-height: 1.2; } } } }

.aside-products {
    border: 1px solid #d9dcdd;
    padding: 22px 17px 26px 17px;
    &__ {
        &title {
            font-weight: 600;
            font-size: 23px;
            line-height: 20px;
            margin: 0 0 35px; } } }

.simple-product {
    padding-right: 5px;
    &:not(:last-child) {
        margin-bottom: 28px; }
    &__ {
        &image {
            padding-bottom: 82%;
            background-position: top center;
            background-size: contain;
            background-repeat: no-repeat; }
        &link {
            color: #169bd5;
            text-decoration: underline; }
        &title {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin: 16px 0 8px; }
        &price {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-align: right;
            margin: 13px 0 0; } }
    .product-properties {
        font-size: 14px; }
    .product-property {
        margin-bottom: 5px;
        &__name {
            margin-right: 20px; } } }

.page-title {
    margin: 15px 0 25px;
    font-size: 40px;
    line-height: 1;
    font-weight: 500;
    @media (max-width: 767px) {
        margin-top: 0;
        line-height: 1; } }

.sort {
    margin: 36px 0 48px; }

.app.category-items {
    .page-title {
        margin: 15px 0 43px;
        @media (max-width: 767px) {
            margin: 0 0 23px; } }
    .form-box {
        padding-bottom: 26px;
        &__ {
            &title {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 20px; } } }
    .pagination {
        margin-top: 62px;
        @media (max-width: 1023px) {
            &__list {
                justify-content: center; } }
        @media (max-width: 767px) {
            margin-top: 25px;
            &__list {
                justify-content: flex-start; } } } }

.category-products-found-text {
    font-size: 20px;
    margin: 20px 0;
    font-weight: 400;
    span {
        color: $red;
        white-space: nowrap; }
    a {
        color: #00b2ff;
        text-decoration: underline;
        &:hover {
            color: $red; } } }

.similar-products {
    &__ {
        &title {
            font-size: 30px;
            font-weight: 600;
            line-height: 1;
            margin: 0; }
        &item {
            font-size: 26px;
            margin: 27px 0 17px;
            line-height: 1.3; }
        &link {
            color: #169bd5;
            text-decoration: underline; } } }

.faq {
    &-categories-nav__item, &-group__item {}
    &-categories-nav__link, &-group__link {
        color: #169bd5;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.3;
        margin-top: 5px;
        margin-bottom: 12px; } }

.faq-item, .faq-group {
    &__ {
        &link {
            padding-left: 23px;
            margin-left: -23px;
            @media (max-width: 767px) {
                padding-left: 0;
                margin-left: 0;
                padding-right: 30px;
                display: block; }
            &.active {
                & > .faq-item__arrow-icon,
                & > .faq-group__arrow-icon {
                    transform: rotate(180deg);
                    opacity: 1; } } }
        &arrow-icon {
            position: absolute;
            left: 0;
            top: 12px;
            transform: rotate(0deg);
            opacity: 0;
            @media (max-width: 767px) {
                left: inherit;
                right: 0; }
            path {
                fill: currentColor; } } } }

.faq-item {
    margin: 7px 0;
    &__ {
        &link {
            color: #9ca5ac;
            font-size: 20px;
            line-height: 1.3;
            font-weight: 600;
            position: relative;
            padding-left: 23px;
            margin-left: -23px;
            @media (max-width: 767px) {
                font-size: 16px;
                .faq-item__arrow-icon {
                    top: 9px; } } }
        &description {
            padding: 10px 0 13px;
            display: none;
            @media (max-width: 767px) {
                padding: 15px 25px 13px 0; }
            p {
                line-height: 22px;
                margin: 0 0 22px;
                &:last-child, &:only-child {
                    margin: 0; } } } } }

.faq-group {
    margin-bottom: 50px;
    &__ {
        &link {
            position: relative;
            @media (max-width: 767px) {
                font-size: 20px;
                .faq-group__arrow-icon {
                    top: 13px; } } } }
    &__items {
        padding: 1px 0; }
    .faq-item__link.active>.faq-item__arrow-icon {
        opacity: 1; } }

.page-search-form {
    .form {
        display: flex; }
    .btn-gradient {
        max-width: 137px;
        min-width: inherit;
        margin-left: 18px;
        flex-grow: 0;
        flex-shrink: 0;
        @media (max-width: 767px) {
            max-width: 80px;
            margin-left: 15px; } } }

.page-search-categories {
    margin-bottom: 47px; }

.categories {
    padding: 30px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
.category {
    max-width: 328px;
    width: 100%;
    margin: 25px 0 30px;
    @media (max-width: 767px) {
        max-width: inherit; }
    &__ {
        &image {
            padding-bottom: 60%;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; }
        &title {
            font-size: 20px;
            font-weight: 500;
            margin: 23px 0 10px;
            line-height: 24px;
            text-decoration: underline;
            max-width: 250px; }
        &description {
            p {
                margin: 20px 0 0; } } } }

.app.brands {
    .page-title {
        margin-bottom: 18px; }
    .brands-by-alphabet {
        padding-bottom: 0;
        border-bottom: none;
        &__list {
            margin-top: 34px;
            margin-bottom: -40px; } } }

.page-articles {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -25px;
    @media (max-width: 1023px) {
        margin: 0 -15px; }
    @media (max-width: 767px) {
        margin: 0; }
    &__title {
        font-size: 30px;
        text-align: center;
        font-weight: 400;
        margin: 10px 0;
        width: 100%; }
    .article {
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        @media (max-width: 1023px) {
            padding: 0 15px; }
        @media (max-width: 767px) {
            padding: 0;
            display: block; }
        .article__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            @media (max-width: 767px) {
                height: auto; } }
        .article__tags {
            margin-top: auto; } }
    .subscribe {
        margin: 0 25px 35px;
        width: 100%;
        @media (max-width: 1023px) {
            margin: 30px 15px 25px; }
        @media (max-width: 767px) {
            margin: 0 0 60px;
            padding: 22px 18px 35px 24px;
            &~.article {
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
                .article__ {
                    &image-container {
                        width: 50%; }
                    &image {
                        padding-bottom: 73%;
                        background-size: cover; }
                    &content {
                        width: 50%;
                        padding-left: 20px; }
                    &title {
                        margin-top: 5px; }
                    &tags {
                        margin-bottom: 10px; } } } } } }
.article {
    width: 33.33%;
    margin: 15px 0 33px;
    @media (max-width: 1023px) {
        margin-bottom: 20px; }
    @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 50px; }
    &__ {
        &image {
            padding-bottom: 60%;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; }
        &link {
            color: #169bd5; }
        &title {
            color: inherit;
            font-size: 20px;
            line-height: 1;
            font-weight: 400;
            text-decoration: underline;
            margin: 16px 0 15px;
            @media (max-width: 1023px) {
                font-size: 16px; }
            @media (max-width: 767px) {
                font-size: 20px;
                margin: 23px 0 22px; } }
        &tags {
            display: flex;
            flex-wrap: wrap;
            height: 24px;
            overflow: hidden; }
        &tag {
            margin-right: 17px;
            padding: 0 11px;
            height: 24px;
            border-radius: 3px;
            background-color: #f3f3f3;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 24px;
            color: #9ca5ac;
            @media (max-width: 374px) {
                margin-right: 5px;
                padding: 0 7px; } } } }

.subscribe {
    padding: 28px 24px 27px 22px;
    background: #e2f6ff;
    display: flex;
    align-items: center;
    @media (max-width: 1023px) {
        display: block; }
    &__title {
        color: #3886a8;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        @media (max-width: 1023px) {
            margin-bottom: 18px;
            max-width: 570px; }
        @media (max-width: 767px) {
            margin-bottom: 14px; } }
    .form {
        width: 375px;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        @media (max-width: 1023px) {
            width: 100%; }
        @media (max-width: 767px) {
            display: block; }
        .light-input {
            padding: 0 19px;
            @media (max-width: 767px) {
                margin: 0 0 18px; }
            &::placeholder {
                opacity: 1;
                color: #000; } }
        .btn-gradient {
            width: 137px;
            min-width: inherit;
            margin-left: 26px;
            flex-shrink: 0;
            flex-grow: {}
            @media (max-width: 767px) {
                width: 100%;
                margin-left: 0; } } } }

.article-tags-list {
    margin: 50px 0 25px;
    max-width: 750px;
    @media (max-width: 767px) {
        display: none; }
    .article__tag {
        margin: 0 5px 18px 0; } }

.article-text {
    img {
        margin-bottom: 20px; } }

.page-tag {
    margin-top: -13px;
    margin-bottom: 25px; }

.app.articles {
    .main {
        .pagination {
            margin-top: 20px;
            @media (max-width: 767px) {
                margin-bottom: 20px; }
            &__list {
                justify-content: center;
                @media (max-width: 767px) {
                    justify-content: flex-start; } } } } }

.app.article-read {
    .main {
        margin-bottom: 100px;
        .main__article_aside-wrapper {
            border-bottom: none; } } }

.about-text {
    margin-bottom: 100px;
    @media (max-width: 767px) {
        margin-bottom: 0; }
    &-intro {
        display: flex;
        align-items: center;
        margin: -65px -40px 51px;
        font-size: 18px;
        @media (max-width: 1023px) {
            margin-top: -25px; }
        @media (max-width: 767px) {
            margin: 0;
            font-size: 16px;
            margin-bottom: 23px; }
        & &__ {
            &text, &image {
                width: 50%;
                padding: 0 40px;
                @media (max-width: 767px) {
                    width: 100%;
                    padding: 0; } }
            &text {
                margin: 55px 0;
                @media (max-width: 1023px) {
                    margin: 0; }
                p {
                    &:last-child {
                        margin-bottom: 0; } } }
            &image {
                @media (max-width: 767px) {
                    display: none; }
                & img {
                    margin: 0; } } } }
    &__columns {
        columns: 2;
        column-gap: 80px;
        margin-bottom: 30px;
        @media (max-width: 767px) {
            columns: 1; }
        p {
            -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
            page-break-inside: avoid;
            break-inside: avoid-column; } }
    p {
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 23px; }
    &__image {
        margin-bottom: 63px;
        background-size: cover;
        padding-bottom: 36.22%;
        background-position: top center;
        @media (max-width: 767px) {
            margin-bottom: 50px;
            padding-bottom: 85%; } }
    img {
        margin-bottom: 63px;
        @media (max-width: 767px) {
            margin-bottom: 50px; } } }

.where-to-buy {
    &__ {
        &description {
            font-size: 20px;
            margin-top: -6px;
            line-height: 22px;
            @media (max-width: 1023px) {
                font-size: 18px; }
            p {
                margin-top: 45px; } }
        &shops {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: -20px -20px 0;
            @media (max-width: 767px) {
                display: block;
                margin-top: 45px; } } }
    &-shop {
        width: 25%;
        margin: 45px 0 0;
        padding: 0 20px;
        padding-right: 40px;
        @media (max-width: 767px) {
            width: 100%;
            margin: 0 0 25px; }
        &__link {
            display: inline-block;
            vertical-align: top; }
        &__image {
            max-height: 45px;
            display: block; } } }

.bulk-purchases-feedback {
    padding: 20px 200px 25px 35px;
    background: #e2f6ff;
    position: relative;
    margin: 85px 0 0;
    @media (max-width: 1023px) {
        padding: 12px 200px 14px 35px; }
    @media (max-width: 767px) {
        display: none; }
    &:after {
        content: '';
        height: 149px;
        width: 122px;
        background: url(../img/person.png) no-repeat;
        position: absolute;
        bottom: 0;
        right: 65px; }
    p {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        @media (max-width: 1023px) {
            line-height: 1.2; }
        a {
            color: #28a1d7;
            text-decoration: underline;
            @media (max-width: 1023px) {
                display: table; }
            &:hover {
                color: $red; } } } }

.in-row-categories {
    ul {
        padding: 0;
        margin: 0;
        list-style: none; }
    &__ {
        &title {
            font-size: 30px;
            font-weight: 400;
            margin: 45px 0 0; }
        &items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media (max-width: 767px) {
                display: block; } }
        &item {
            width: 33.33%;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                width: 100%; }
            &.breakable {
                width: 100%;
                .in-row-subcategories {
                    columns: 3;
                    column-gap: 0;
                    @media (max-width: 767px) {
                        columns: 1; } } }
            .in-row-subcategory {
                max-width: 270px;
                @media (max-width: 1023px) {
                    max-width: 240px; }
                @media (max-width: 767px) {
                    max-width: 270px; } } } } }

.in-row-category {
    &__ {
        &title {
            margin: 25px 0 10px;
            font-size: 20px;
            font-weight: 700; } }
    .in-row-subcategory {
        margin-bottom: 12px;
        line-height: 1.2; } }

.inline-articles {
    position: relative;
    padding: 42px 0 14px;
    &:before {
        content: '';
        height: 1px;
        background: #e8eaeb;
        position: absolute;
        top: 0;
        left: -100vw;
        right: -100vw;
        width: 300vw; }
    &__ {
        &title {
            margin: 0;
            font-size: 20px;
            font-weight: 600; }
        &list {
            display: flex;
            align-items: stretch;
            justify-content: space-between; } }
    .article {
        display: flex;
        align-items: center;
        max-width: 328px;
        @media (max-width: 1023px) {
            width: 50%;
            &:nth-child(2)~div {
                display: none; } }
        &__ {
            &image-container {
                flex-grow: 0;
                flex-shrink: 0;
                width: 135px;
                margin-right: 20px; }
            &image {
                background-size: cover;
                padding-bottom: 72%; }
            &title {
                font-size: 14px;
                padding-right: 30px;
                line-height: 1.5; } } } }

.card-description {
    display: inline-block;
    width: 100%;
    margin-bottom: 37px;
    &__image-container {
        width: 300px;
        float: left;
        margin-right: 20px;
        &+p {
            padding-top: 10px; } }
    &__image {
        padding-bottom: 70%;
        background-position: top center;
        background-size: cover; }
    h2, h3 {
        font-size: 30px;
        margin: 10px 0 25px;
        line-height: 1; }
    p {
        margin: 15px 0; } }

.tab .inline-articles {
    margin-top: 1px;
    &:before {
        left: 0;
        right: 0;
        width: 100%; } }

.reviews {
    &__ {
        &header {
            display: flex;
            align-items: center;
            margin-bottom: 23px; }
        &title {
            margin: 0;
            font-size: 30px;
            font-weight: 600;
            @media (max-width: 767px) {
                font-size: 26px; } }
        &btn-more {
            text-align: center;
            margin-top: -8px; } }
    .selectric-wrapper {
        margin-left: auto;
        font-size: 18px;
        width: 250px;
        @media (max-width: 767px) {
            width: 220px; }
        @media (max-width: 374px) {
            width: 180px;
            white-space: nowrap;
            .selectric-items {
                width: auto !important;
                right: 0;
                left: inherit; } } }
    .selectric-hover .selectric .button:after {
        border-top-color: $red; } }

.main .reviews .selectric-wrapper {
    background: #f8f8f8; }

.review {
    border: 1px solid #dbdcde;
    margin-bottom: 42px;
    position: relative;
    &:after {
        content: '';
        background: url(../img/icons/comment-bottom.jpg) no-repeat;
        width: 19px;
        height: 13px;
        position: absolute;
        top: 100%;
        left: 22px; }
    &--response {
        margin-top: -17px;
        margin-left: 90px;
        @media (max-width: 767px) {
            margin-left: 30px; }
        .review-header {
            height: 83px; }
        .review-body {
            padding: 23px 20px 27px; }
        &:after {
            content: '';
            background: url(../img/icons/comment-bottom.jpg) no-repeat;
            width: 19px;
            height: 13px;
            transform: rotate(180deg);
            position: absolute;
            bottom: 100%;
            right: 32px;
            top: inherit;
            left: inherit; } }

    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid #dbdcde;
        min-height: 58px;
        padding: 0 20px;
        @media (max-width: 767px) {
            padding: 15px 20px; }
        &__ {
            &date {
                color: #6b7d8b;
                margin-left: 5px;
                font-size: 14px;
                margin-top: 1px;
                display: inline-block;
                line-height: 20px; }
            &rating {
                margin-left: auto;
                display: flex;
                align-items: center;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-top: 5px; }
                &-stars {
                    width: 86px;
                    height: 13px;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100px;
                        background: url(../img/icons/rating.png) no-repeat; }
                    span {
                        height: 100%;
                        background: $red;
                        display: block; } }
                &-sum {
                    width: 31px;
                    height: 19px;
                    line-height: 19px;
                    border-radius: 3px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #fff;
                    background: $red;
                    text-align: center;
                    cursor: default; } }
            &avatar {
                height: 50px;
                margin-right: 10px; }
            &filtero-label {
                width: 49px;
                height: 19px;
                line-height: 19px;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
                background: $red;
                text-align: center;
                cursor: default;
                margin-left: 13px;
                margin-right: 4px;
                margin-bottom: 2px; } } }
    &-body {
        padding: 0 20px 38px;
        &__ {
            &title {
                font-size: 20px;
                font-weight: 600;
                margin: 20px 0 8px;
                padding-right: 200px;
                @media (max-width: 767px) {
                    padding-right: 0; } }
            &content {
                display: flex;
                align-items: flex-start;
                @media (max-width: 767px) {
                    display: block; } }
            &text {
                font-size: 14px;
                line-height: 1.4; }
            &brand {
                width: 180px;
                flex-grow: 0;
                flex-shrink: 0;
                text-align: center;
                margin-left: 20px;
                margin-top: -30px;
                @media (max-width: 767px) {
                    margin-top: 25px;
                    text-align: left;
                    margin-left: 0; }
                &-link {
                    color: #169bd5;
                    text-decoration: underline;
                    font-size: 14px;
                    margin-top: 10px;
                    display: inline-block; } } } } }


.selectric-is-native select {
    left: 0; }

.select2-results {
    max-height: 200px;
    overflow: auto; }

.modal {
    &-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(#666, .6);
        padding: 60px;
        overflow: auto;
        z-index: 60;
        @media (max-width: 1023px) {
            padding: 20px; } }
    & {
        display: table;
        width: 100%;
        height: 100%; }
    &__inner {
        display: table-cell;
        vertical-align: middle; }
    &__body {
        display: table;
        margin: 0 auto;
        position: relative;
        border: 1px solid #c6dee8;
        box-shadow: 0 0 7px rgba(0,0,0,0.22);
        background: #fff;
        @media (max-width: 767px) {
            display: block; } }
    &__close {
        width: 10px;
        height: 10px;
        position: absolute;
        right: 8px;
        top: 9px;
        padding: 4px 0;
        &:before, &:after {
            content: '';
            background: #03b0fc;
            position: absolute;
            width: 10px;
            height: 2px;
            border-radius: 2px; }
        &:before {
            transform: rotate(45deg); }
        &:after {
            transform: rotate(-45deg); } }
    &-city {
        width: 794px;
        background: #e2f6ff;
        padding: 35px 43px;
        @media (max-width: 1023px) {
            width: 700px; }
        @media (max-width: 767px) {
            width: 100%;
            padding: 25px 30px; }
        .hint-link__text {
            color: #6d7d8c; }
        &__header {
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                display: block; } }
        &__title {
            margin: 0;
            font-size: 22px;
            font-weight: 500; }
        &__selected-city {
            margin: 0 90px 0 70px;
            @media (max-width: 1023px) {
                margin: 0 60px 0 40px; }
            @media (max-width: 767px) {
                margin: 10px 0; } }
        &-alphabet {
            &__letters {
                display: flex;
                justify-content: space-between;
                margin-bottom: 33px;
                @media (max-width: 767px) {
                    display: block;
                    margin-bottom: 23px; } }
            &__letter {
                font-weight: 500;
                font-size: 18px;
                display: inline-block;
                padding: 0 5px;
                @media (max-width: 1023px) {
                    font-size: 16px;
                    padding: 0 3px; } }
            &__cities {
                columns: 3;
                column-gap: 60px;
                padding: 0 5px;
                @media (max-width: 1023px) {
                    padding: 0 3px; } }
            &__city {
                display: table;
                margin-bottom: 13px;
                break-inside: avoid-column;
                break-after: avoid;
                page-break-inside: avoid; } }
        &-search {
            position: relative;
            margin-bottom: 18px;
            .select2-search__field {
                outline: none;
                border: none; }
            .select2-container {
                width: 100%!important;
                height: 40px;
                line-height: 40px;
                border: none;
                outline: none;
                border-radius: 4px;
                padding: 0 15px;
                box-shadow: 0 2px 4px rgba(0,0,0,0.17);
                background: #fff;
                &::placeholder {
                    color: #000; } }
            &__submit {
                position: absolute;
                right: 0;
                top: 0;
                background: url(../img/icons/search.svg) center no-repeat;
                background-size: 19px;
                border: none;
                outline: none;
                appearance: none;
                width: 45px;
                height: 100%;
                cursor: pointer;
                opacity: .25;
                transition: .25s ease;
                pointer-events: none;
                &:hover {
                    opacity: 1; } } } }
    &-content {
        width: 700px;
        padding: 35px 63px 35px 43px;
        @media (max-width: 767px) {
            width: 100%;
            padding: 25px 30px; }
        .hint-link__text {
            color: #6d7d8c; }
        &__title {
            margin: 0 0 10px;
            font-size: 22px;
            @media (max-width: 767px) {
                font-size: 18px; } }
        &__description {
            font-size: 20px;
            max-width: 540px;
            @media (max-width: 767px) {
                font-size: 18px; } }
        p {
            font-size: 14px;
            a {
                text-decoration: underline;
                &.blue {
                    color: #06b1f9; }
                &:hover {
                    color: $red; } } } }
    &-choose-shop {
        margin: -10px;
        &__title {
            margin: 0 0 10px;
            font-size: 22px;
            font-weight: 500; }
        &__shop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 19px 20px;
            border-radius: 3px;
            border: 1px solid transparent;
            margin-bottom: 13px;
            color: #169bd4;
            @media (max-width: 767px) {
                display: block;
                border-color: #d8dcdd;
                position: relative;
                padding-bottom: 15px; }
            &:hover {
                border-color: #07aaef;
                color: #169bd4; } }
        &__shop-logo {
            width: 130px;
            @media (max-width: 767px) {
                margin-bottom: 12px; } }
        & &__shop-choose-btn {
            max-width: 90px;
            min-width: inherit;
            padding: 0;
            @media (max-width: 767px) {
                position: absolute;
                top: 20px;
                right: 20px; }
            @media (max-width: 375px) {
                position: static;
                margin: 10px 0 5px; } } } }
