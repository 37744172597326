.top-nav {
    @media (max-width: 767px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 385px;
        display: block;
        background: #fff;
        max-width: inherit!important;
        border-bottom: 1px solid #eee; }
    &__ {
        &list {
            @extend %flex-justify;
            width: 100%;
            @media (max-width: 767px) {
                display: block;
                padding: 29px 20px 25px;
                font-size: 18px; } }
        &item {
            &.active {
                pointer-events: none;
                .top-nav__link {
                    color: $red; } }
            @media (max-width: 767px) {
                margin: 15px 0; } }
        &link {
            font-size: 14px;
            font-weight: 500;
            @media (max-width: 767px) {
                font-size: 18px; } } } }

.main-nav {
    height: 55px;
    margin-bottom: 5px;
    @media (max-width: 767px) {
        height: auto;
        margin: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 49;
        background: #fff; }
    &__ {
        &list {
            @extend %flex-justify;
            width: 100%;
            @media (max-width: 767px) {
                display: block; } }
        &item {
            @media (max-width: 767px) {
                border: none;
                border-bottom: 1px solid #eee;
                &.active {
                    background: #eeeeee;
                    .main-nav__expand-right {
                        path {
                            fill: $red; } }
                    .main-nav__dropdown {
                        display: block!important;
                        &>.container {
                            animation: fadeIn .7s ease; } } } }
            &:first-child {
                padding-right: 20px;
                @media (max-width: 767px) {
                    padding: 0; } }
            &:hover {
                .main-nav__link {
                    z-index: 51;
                    @media (max-width: 767px) {
                        border-color: transparent;
                        &:hover {
                            background: #eeeeee;
                            .main-nav__expand-right {
                                path {
                                    fill: $red; } } } } }
                .main-nav__dropdown {
                    @media (max-width: 767px) {
                        display: none; }
                    &>.container {
                        animation: fadeIn .5s ease; } } } }
        &link {
            display: block;
            color: $red;
            font-size: 18px;
            line-height: 1;
            margin: 10px -15px -15px;
            padding: 10px 15px 15px;
            border: 1px solid transparent;
            border-bottom-color: #fff;
            @media (max-width: 767px) {
                padding: 0 20px;
                margin: 0;
                line-height: 58px;
                position: relative;
                display: flex;
                align-items: center;
                .main-nav__expand-right {
                    margin-left: auto;
                    transform: rotate(-90deg); } } } }

    &__dropdown {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: -6px;
        background: #fff;
        z-index: 50;
        padding-top: 20px;
        max-height: calc(100vh - 80px);
        overflow-y: auto;
        overflow-x: hidden;
        @media (max-width: 767px) {
            top: 5px;
            padding-top: 0;
            z-index: 52; }
        & > .container {
            padding: 0 20px; }
        .brands-by-alphabet {
            border-bottom: none;
            &__list {
                margin-top: 26px; } }
        .alphabet-nav {
            margin-bottom: 24px;
            @media (max-width: 767px) {
                margin-bottom: -1px; }
            &__title {
                margin: 0; } }
        .simple-nav {
            margin: 27px 0 0;
            &__item {
                margin-right: 30px; } }
        .brand-help-link {
            display: inline-block;
            margin: 6px 0 43px;
            color: #169bd5;
            text-decoration: underline;
            &:hover {
                color: $red; }
            @media (max-width: 767px) {
                display: none; } }
        .categories {
            padding-top: 0;
            margin-bottom: 28px;
            justify-content: flex-start;
            margin-left: -1%;
            margin-right: -1%;
            .category {
                width: 31%;
                margin-left: 1%;
                margin-right: 1%; }
            &--4-in-row {
                .category {
                    width: 23%;
                    margin-left: 1%;
                    margin-right: 1%; } }
            &--text {
                padding: 42px 0;
                @media (max-width: 1023px) {
                    padding: 15px 0; }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: #f3f3f3;
                    @media (max-width: 767px) {
                        display: none; } }
                .category {
                    margin-left: 1%;
                    margin-right: 1%;
                    position: relative; }
                .category__image-container {
                    display: none; }
                .category__title {
                    font-size: 16px;
                    margin: 16px 0;
                    font-weight: 600; } } }
        .category {
            margin: 22px 0 10px;
            &__description {
                display: none; }
            &__title {
                margin-top: 13px;
                @media (max-width: 1023px) {
                    font-size: 18px; } } }
        & {
            .categories .category {
                @media (max-width: 767px) {
                    width: 100%;
                    .category__image-container {
                        display: block; } } } } } }

.has-nav-opened {
    height: 100vh;
    overflow: hidden; }

.main-nav__dropdown {
    background: #f3f3f3; }
.has-main-nav-overlay {
    .main-nav__item {
        .main-nav__dropdown {
            .container {
                animation: none; } }
        &.hover {
            .main-nav__link {
                background: #f3f3f3; }
            @media (min-width: 768px) {
                .main-nav__dropdown {
                    display: block; } } } } }

.has-main-nav-overlay, .has-nav-opened {
    &:before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(#666, .6);
        z-index: 15;
        animation: fadeIn .5s ease; }
    .header {
        z-index: 30;
        background: #fff; } }

.brands-nav {
    display: flex;
    padding: 37px 90px 37px 0;
    @media (max-width: 1023px) {
        padding-right: 0; }
    @media (max-width: 767px) {
        padding: 45px 0 41px 0;
        font-size: 18px; }
    &__ {
        &title {
            margin: 0;
            width: 150px;
            flex-grow: 0;
            flex-shrink: 0;
            font-weight: 600;
            line-height: 1.2;
            &-link {
                color: #fff; }
            @media (max-width: 767px) {
                width: 124px; } }
        &list {
            @extend %flex-justify;
            width: 100%;
            @media (max-width: 767px) {
                display: block;
                max-width: 320px;
                margin-left: auto; } }
        &item {
            &.active {
                pointer-events: none; }
            @media (max-width: 767px) {
                width: 50%;
                float: left;
                padding-bottom: 3px; } }
        &link {
            color: #54595d;
            &:hover {
                color: #fff; } } } }

.footer-nav {
    &__ {
        &title {
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 63px;
            pointer-events: none;
            @media (max-width: 767px) {
                margin: 0 0 36px;
                pointer-events: auto; }
            &-link {
                color: #fff;
                @extend %flex-center;
                &:hover {
                    color: #fff;
                    .footer-nav__expand-bottom {
                        transform: scale(1);
                        transition: .3s ease;
                        path {
                            fill: $red; } } } }
            &.active {
                .footer-nav__expand-bottom {
                    transform: scale(-1);
                    path {
                        fill: $red; } } } }
        &expand-bottom {
            float: right;
            margin-left: auto;
            path {
                transition: .15s ease; }
            @media (max-width: 767px) {
                display: block; } }
        &list {
            line-height: 1.9;
            @media (min-width: 768px) {
                display: block !important; }
            @media (max-width: 767px) {
                display: none;
                padding: 7px 0 34px; } }
        &item {
            &.active {
                pointer-events: none; }
            @media (max-width: 767px) {
                padding-bottom: 10px; } }
        &link {
            color: #54595d;
            &:hover {
                color: #fff; } } } }

.alphabet-nav {
    break-inside: avoid-column;
    page-break-inside: avoid;
    break-inside: avoid-column;
    display: inline-block;
    width: 100%;
    margin-bottom: 52px;
    white-space: nowrap;
    @media (max-width: 767px) {
        margin-bottom: 0;
        display: block; }
    &__ {
        &title {
            margin:  0 0 26px;
            font-size: 20px;
            font-weight: 700;
            @media (max-width: 767px) {
                margin: 0;
                pointer-events: auto; }
            &-link {
                pointer-events: none;
                @extend %flex-center;
                @media (max-width: 767px) {
                    pointer-events: auto;
                    margin: 0 -20px;
                    padding: 9px 20px;
                    border-top: 1px solid #e8eaeb;
                    border-bottom: 1px solid #e8eaeb;
                    margin-bottom: -1px; }
                &:hover {
                    color: $black;
                    .alphabet-nav__expand-bottom {
                        transform: scale(1);
                        transition: .3s ease;
                        path {
                            fill: $red; } } } }
            &.active {
                .alphabet-nav__expand-bottom {
                    transform: scale(-1);
                    path {
                        fill: $red; } } } }
        &expand-bottom {
            float: right;
            margin-left: auto;
            path {
                transition: .15s ease;
                fill: #d1d5d8; }
            @media (max-width: 767px) {
                display: block; } }
        &list {
            line-height: 1.9;
            @media (max-width: 767px) {
                padding: 17px 0;
                display: none; } } } }

.simple-nav {
    margin-bottom: 20px;
    margin-right: -60px;
    &__ {
        &list {
            @extend %flex-start;
            flex-wrap: wrap; }
        &item {
            margin-right: 60px;
            margin-bottom: 10px;
            font-weight: 600;
            &.active {
                pointer-events: none;
                .simple-nav__link {
                    color: $red;
                    border-bottom: none; } } }
        &link {
            display: block;
            color: $red;
            font-size: 20px;
            color: #9ca5ac;
            border-bottom: 1px dashed;
            &:hover {
                border-bottom-color: $red; } } } }

.page-search-categories {
    .simple-nav__list {
        @media (max-width: 767px) {
            display: block; } }
    .simple-nav__item {
        margin-right: 48px;
        font-weight: 500;
        @media (max-width: 767px) {
            display: table; } } }

.tabs {
    .simple-nav__item {
        @media (max-width: 767px) {
            margin-right: 40px; }
        @media (max-width: 374px) {
            margin-right: 17px; } } }


.articles-categories {
    margin: -10px 0 2px;
    @media (max-width: 767px) {
        margin: 0 0 28px; }
    .simple-nav {
        margin-bottom: 0; }
    .simple-nav__item {
        margin-right: 50px;
        @media (max-width: 374px) {
            margin-right: 30px; } }
    .simple-nav__link {
        font-size: 16px;
        line-height: 20px; } }

.pathway-nav {
    padding: 15px 0;
    @media (max-width: 767px) {
        padding: 19px 0 13px; }
    &__ {
        &list {
            display: flex;
            flex-wrap: wrap; }
        &item {
            font-size: 14px;
            color: #9ca5ac;
            font-weight: 400;
            &:not(:last-child):after {
                content: '-';
                float: right;
                color: #9ca5ac;
                font-family: Arial, sans-serif;
                margin: 0 5px; }
            @media (max-width: 767px) {
                &:not(.pathway-nav__item--parent) {
                    display: none; }
                &:after {
                    display: none; } } }
        &link {
            color: #169bd5;
            float: left;
            @extend %flex-center; }
        &angle-left {
            margin-right: 20px; } } }

.pagination {
    &__ {
        &list {
            display: flex;
            flex-wrap: wrap; }
        &item {
            &.active {
                pointer-events: none;
                .pagination__link {
                    color: $red; } }
            &:not(:last-child) {
                margin-right: 56px; } }
        &link {
            font-size: 24px;
            font-weight: 500;
            line-height: 30px; } } }

.aside-nav {
    border: 1px solid #d9dcdd;
    padding: 24px 10px 12px 17px;
    margin-bottom: 29px;
    &__ {
        &title {
            font-size: 22px;
            line-height: 20px;
            font-weight: 600;
            margin: 0 0 18px; }
        &list {
            font-size: 14px;
            line-height: 1.3; }
        &item {
            margin-bottom: 15px;
            &--category-link {
                .aside-nav__link {
                    color: $red;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none; } } }
            &.active {
                pointer-events: none;
                .aside-nav__link {
                    color: #9ca5ac; } } }
        &link {
            color: #169bd5; } } }

.faq-categories-nav {
    margin-bottom: 60px;
    @media (max-width: 767px) {
        margin-bottom: 33px; }
    &__ {
        &item {
            margin: 0 0 12px; }
        &link {
            border-bottom: 1px dashed #ced2d6; } } }

@media (max-width: 767px) {
    .app {
        .main-nav, .top-nav {
            display: none; }
        &.has-nav-opened {
            .header__logo {
                display: none; }
            .main-nav, .top-nav {
                display: block;
                visibility: visible;
                &>* {
                    animation: fadeIn .3s ease; } }
            .header__top-nav_city-select .select {
                display: block;
                margin-left: 0;
                position: absolute;
                white-space: nowrap;
                top: 28px;
                .select__title {
                    display: none; }
                .selectric-wrapper {
                    font-size: 18px;
                    max-width: 180px; }
                .selectric-items {
                    left: 0;
                    right: inherit; } }
            &.has-nav-dropdown-opened {
                .top-nav {
                    visibility: hidden; }
                .header__top-nav_city-select {
                    .select {
                        display: none; } }
                .header-nav-title {
                    display: block;
                    color: #fff;
                    .main-nav__link {
                        line-height: 1;
                        white-space: nowrap;
                        padding: 0;
                        flex-direction: row-reverse;
                        .main-nav__expand-right {
                            transform: rotate(90deg);
                            margin-right: 17px;
                            path {
                                fill: $red; } } } } } } } }
