.app {
    overflow: hidden; }

.container {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    .container {
        padding: 0; } }

.hide {
    display: none; }
.show-on-mobile {
    display: none;
    @media (max-width: 767px) {
        display: block; } }
.hide-on-mobile {
    @media (max-width: 767px) {
        display: none; } }

.header {
    @extend %flex-column;
    position: relative;
    &__ {
        &group {
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.22);
            .container {
                @extend %flex-center;
                @media (max-width: 767px) {
                    height: 80px; } }
            @media (max-width: 767px) {
                position: relative;
                z-index: 51;
                .header__hamburger {
                    margin-left: 30px; } } }
        &main {
            @extend %flex-column;
            padding-left: 60px;
            width: 100%;
            @media (max-width: 767px) {
                padding-left: 0; } }
        &top-nav_city-select {
            @extend %flex-center;
            position: relative;
            padding: 4px 0 7px;
            @media (max-width: 767px) {
                padding: 0;
                position: static; }
            &:after {
                content: '';
                left: 0;
                bottom: 0;
                width: 100vw;
                height: 1px;
                background: #e7e7e7;
                position: absolute;
                @media (max-width: 767px) {
                    display: none; } }
            .top-nav {
                width: 100%;
                max-width: 400px;
                @media (max-width: 1023px) {
                    max-width: 260px; } }
            .select {
                margin-left: auto;
                @media (max-width: 767px) {
                    display: none; } } }
        &hint-link_search-box {
            @extend %flex-center;
            .hint-link {
                margin-bottom: 5px; }
            .search-box {
                margin-left: auto;
                max-width: 450px;
                width: 100%;
                @media (max-width: 1023px) {
                    max-width: 220px; }
                @media (max-width: 767px) {
                    width: auto; } } }
        &hamburger-icon {
            margin-left: 30px; } } }

.main {
    &__pathway {
        border-top: 1px solid rgba(209,213,216,0.5); }
    &__article_aside-wrapper {
        border-bottom: 1px solid rgba(209,213,216,0.5);
        padding-bottom: 35px; }
    &__article_aside {
        display: flex;
        flex-direction: row-reverse;
        @media (max-width: 1023px) {
            display: block; } }
    &__article {
        width: 100%; }
    &__aside {
        width: 230px;
        margin-left: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        @media (max-width: 1023px) {
            display: none; } } }

.footer {
    position: relative;
    background: #000;
    color: #54595d;
    font-weight: 500;
    padding: 0 0 60px;
    &:before {
        content: '';
        position: absolute;
        max-width: 1000px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url(../img/pictures/footer/bg.jpg) left center no-repeat;
        background-size: auto 100%;
        margin: 0 auto;
        opacity: .2;
        @media (max-width: 767px) {
            background-size: 100% auto;
            background-position: left bottom; } }
    .container {
        position: relative; }
    &__ {
        &brands-nav {
            border-bottom: 1px solid rgba(#fff, .15); }
        &navs {
            @extend %flex-start;
            justify-content: space-between;
            padding: 51px 0;
            @media (max-width: 767px) {
                display: block;
                padding: 43px 0 38px; }
            .footer-nav {
                max-width: 35%;
                @media (max-width: 767px) {
                    max-width: inherit; } } }
        &socials_copyright {
            @extend %flex-center;
            padding-top: 5px;
            .socials {
                margin-left: auto; } } } }
