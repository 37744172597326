* {
    box-sizing: border-box;
    outline-color: $red; }

html {
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    color: $black;
    line-height: 1.5; }

nav {
    ul {
        padding: 0;
        margin: 0;
        list-style: none; } }

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto; }

a {
    text-decoration: none;
    color: $black;
    &:hover {
        color: $red;
        transition: .15s ease; } }

p img {
    display: inherit; }

.is-disabled {
    pointer-events: none; }

@keyframes fadeIn {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }
