button, input, optgroup, select, textarea {
    font-family: inherit; }

.btn-rect {
    color: $red;
    font-size: 13px;
    font-weight: 600;
    line-height: 46px;
    padding: 0 43px;
    text-transform: uppercase;
    display: inline-block;
    border: 2px solid $red;
    transition: .15s;
    text-align: center;
    &:hover, &:active {
        background: $red;
        color: #fff; }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0; }
    &--black {
        color: $black;
        border-color: $black;
        text-transform: none;
        &:hover {
            background: $black;
            color: #fff; } } }

.btn-gray {
    display: inline-block;
    min-width: 338px;
    padding: 0 35px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 3px;
    border: 1px solid rgba(#9ca5ac, .4);
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 767px) {
        padding: 0;
        min-width: inherit;
        width: 100%; } }

.btn-gradient {
    box-shadow: 0 2px 3px rgba(255, 30, 0, 0.41);
    border-radius: 3px;
    background-image: linear-gradient(to top, #d51900 0%, #ff4931 100%);
    color: #fff;
    height: 50px;
    line-height: 48px;
    padding: 0 35px;
    min-width: 230px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    &__dashed {
        border-bottom: 1px dashed;
        transition: .15s ease; }
    &:hover {
        color: #fff;
        .btn-gradient__dashed {
            border-bottom-color: transparent; } }
    &--blue {
        background-image: linear-gradient(to top, #169bd5 0%, #00b2ff 100%);
        box-shadow: 0 2px 3px rgba(22, 155, 213, 0.22);
        min-width: inherit;
        padding: 0;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        transition: .15s ease;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover {
            opacity: .9; } }
    @media (max-width: 1023px) {
        padding: 0 25px;
        min-width: 215px; }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 0; } }

.btn-white {
    height: 50px;
    line-height: 50px;
    border: 1px solid #d7dbde;
    outline: none;
    font-weight: 500;
    display: inline-block;
    padding: 0 27px;
    border-radius: 3px; }

.input {
    height: 40px;
    border-radius: 3px;
    border: 1px solid #d9dcdd;
    background-color: #ffffff;
    padding: 0 15px;
    outline-color: #00b2ff;
    width: 100%; }

.light-input {
    height: 40px;
    line-height: 40px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    border-radius: 3px;
    background-color: #fff;
    border: none;
    width: 100%;
    outline: none;
    padding: 0 14px; }

.light-textarea {
    height: 90px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    border-radius: 3px;
    background-color: #fff;
    border: none;
    width: 100%;
    outline: none;
    padding: 10px 14px;
    resize: none; }

.main .form .selectric-wrapper {
    .label {
        padding: 0 35px 0 14px; }
    .button {
        right: 10px; }
    &:hover, &.selectric-open {
        .button:after {
            border-top-color: $red;
            transition: .15s ease; } } }

.search-box {
    @extend %flex-center;
    width: 100%;
    @media (max-width: 767px) {
        .select2 {
            display: none; } }
    &.is-focus {
        .select2 {
            display: block; }
        .search-box__form {
            border-bottom-color: $red; }
        .search-box__icon {
            path {
                fill: $red; } }
        @media (max-width: 767px) {
            .search-box__form {
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(255, 255, 255, 0.9);
                z-index: 10;
                padding: 80px 20px;
                align-items: flex-start;
                animation: fadeIn .3s ease;
                border-bottom: none;
                .select2 {
                    border-bottom: 2px solid $red; }
                .select2-search__field {
                    display: block;
                    width: 100% !important;
                    background: transparent; } }
            .select2-search--inline {
                min-width: 100%; }
            .search-box__icon path {
                fill: #d1d5d8; }
            .search-box__close {
                display: block;
                position: fixed;
                right: 84px;
                top: 27px;
                z-index: 30; } } }
    select {
        display: none; }
    &__ {
        &form {
            @extend %flex-center;
            border-bottom: 2px solid transparent;
            transition: .2s ease;
            width: 100%;
            @media (max-width: 767px) {
                transition: none; } }
        &input, &form .select2-search__field {
            width: 100%;
            font-size: 22px;
            height: 64px;
            -webkit-appearance: none;
            border: none;
            padding: 0 0 2px;
            outline: none;
            caret-color: rgba($black, .2);
            &::placeholder {
                color: #d1d5d8;
                opacity: 1; }
            @media (max-width: 767px) {
                display: none; } }
        &label {
            height: 100%;
            margin-top: 3px;
            margin-left: -40px;
            position: relative;
            left: 20px;
            @media (max-width: 767px) {
                margin-left: 0;
                left: 0; } }
        &submit {
            display: none; }
        &icon {
            fill: #d1d5d8;
            width: 20px;
            height: 20px;
            display: block;
            cursor: pointer;
            path {
                transition: .2s ease; }
            @media (max-width: 767px) {
                width: 31px;
                height: 31px;
                path {
                    transition: none; } } } } }

.select {
    display: flex;
    .selectric-wrapper {
        width: 100%;
        .button:after {
            top: 2px; }
        &:hover .button:after, &.selectric-open .button:after {
            border-top-color: $red; } } }

.header {
    .select {
        font-size: 14px;
        color: #9ca5ac;
        &__ {
            &title {
                font-weight: 400;
                margin-right: 20px;
                white-space: nowrap;
                @media (max-width: 1023px) {
                    margin-right: 8px; } }
            &select {
                font-weight: 500;
                height: 20px;
                display: none; } }
        .selectric-wrapper {
            display: inline-block;
            vertical-align: bottom;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            max-width: 150px;
            @media (max-width: 1023px) {
                max-width: 125px; }
            .selectric-items {
                white-space: nowrap;
                width: auto !important;
                left: inherit;
                right: 0; }
            .label {
                padding: 0;
                margin-right: 25px;
                border-bottom: 1px dashed rgba(#adadad, .5);
                line-height: 1.3;
                @media (max-width: 1023px) {
                    margin-right: 20px; } } } } }

.main {
    .selectric-wrapper {
        height: 40px;
        line-height: 40px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        background-color: #fff;
        .label {
            padding: 0 48px 0 20px; }
        .button {
            right: 18px; }
        .selectric-items {
            li {
                padding: 2px 20px; } } } }

.form-box {
    background: #e2f6ff;
    padding: 20px 30px 35px;
    @media (max-width: 767px) {
        padding: 20px; }
    &__ {
        &title {
            color: #3886a8;
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 25px;
            @media (max-width: 767px) {
                font-size: 24px;
                line-height: 30px;
                margin: 0 0 22px; } }
        &categories {
            margin: 30px 0 0;
            .form-box__title {
                margin-bottom: 0 !important; }
            &-list {
                margin: 10px 0 0;
                font-size: 0; } }
        &category {
            display: inline-block;
            width: 50%;
            padding-right: 20px;
            margin-bottom: 15px;
            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0; }
            &-link {
                color: #6b7d8b;
                font-size: 18px;
                text-decoration: underline; } }
        &selects-group {
            @extend %flex-justify;
            margin: 0 -10px 10px;
            @media (max-width: 1023px) {
                display: block; }
            .select {
                width: 50%;
                padding: 0 10px;
                @media (max-width: 1023px) {
                    width: 100%;
                    margin: 20px 0 12px; } } }
        &links-group {
            @extend  %flex-justify;
            .hint-link__text {
                color: #6b7d8b; }
            @media (max-width: 1023px) {
                display: block;
                .hint-link {
                    display: table;
                    margin-bottom: 8px; } } } } }

.bulk-purchases {
    margin-bottom: 50px;
    &__description {
        font-size: 20px;
        line-height: 22px;
        margin: -3px 0 37px;
        p {
            margin: 0; }
        a {
            color: #169bd5;
            text-decoration: underline;
            &:hover {
                color: $red; } } }
    .form {
        padding: 6px 36px 45px 29px;
        background: #e2f6ff;
        @media (max-width: 374px) {
            padding: 6px 20px 35px 20px; }
        &__field-name {
            font-size: 16px;
            color: #6b7d8b;
            margin: 23px 0 5px; }
        .btn-gradient {
            max-width: 137px;
            min-width: inherit;
            margin: 24px 0 0; } } }
