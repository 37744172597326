// Vendors
@import "../fonts/FuturaPT/font.css";
@import "vendor/normalize.min.css";
@import "vendor/lightgallery.min.css";
@import "vendor/lightslider.min.css";
@import "vendor/selectric.min.css";
@import "vendor/select2.min.css";

// Partials
@import "partials/_variables";
@import "partials/_placeholders";

@import "partials/global";
@import "partials/structure";
@import "partials/blocks";
@import "partials/forms";
@import "partials/navs";
